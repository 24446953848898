import Vue from 'vue';

import { getEnv } from '../../utils/env';


import serialService from 'kiwrious-webserial/lib/service/SerialService';
import { SensorReadResult } from "kiwrious-webserial/lib/data/SensorReadResult";
import { SensorDecodedValue } from "kiwrious-webserial/lib/data/SensorDecodedValue";

import SensorValueConductivity from '@/components/sensor/value/SensorValueConductivity/index.vue';
import SensorValue from '@/components/sensor/value/SensorValue/index.vue';
import SessionCard from '@/components/Card/index.vue'
// import { SENSOR_TYPE } from 'kiwrious-webserial/lib/service/SerialRawValue';
import { COLOR, UNIT, OBSERVABLE, OBSERVABLE_FULL_NAME, UNIT_SYMBOL, SHORT_OBSERVABLE, COLOR_BY_SENSOR_TYPE, YAXIS_TITLE_AND_COLOR, CONDUCTIVITY_DEFAULT, CONDUCTIVITY_RESULT_STATUS, SAMPLE_RATE_BY_SENSOR, XAXIS_RANGE_BY_INTERVAL } from '../../constants';
import * as moment from 'moment';
import { HEART_RATE_RESULT_STATUS } from 'kiwrious-webserial/lib/service/HeartRateProcessor';
import { VOC_RESULT_STATUS } from 'kiwrious-webserial/lib/service/SerialVOCDecoder';
import { SENSOR_TYPE } from 'kiwrious-webserial/lib/service/SerialRawValue';

let chartData: any[] = [];

let lastDate = 0;
let chartUpdator: number | null | undefined = null;

let XAXISRANGE = 60;//6000;
const XAXISTYPE = 'numeric';//datetime
const YAXIS_LABELS_SIZE = "13px";
const XAXIS_LABELS_SIZE = "13px";
const YAXIS_TITLE_SIZE = "13px";
const LEGEND_TEXT_SIZE = "13px";
let UPDATE_INTERVAL = 1000;
let TIME_DELTA = 1;
let SAMPLING_RATE = 1;
let XAXIS_DATA_POINT_DENSITY = 60;

let csvDownloadInProgress = false;

let chartScallerCounter = 0;
let peakInRange = false;
let peakRangeMax = 40;

const yAxisBandSize = 20;

window.onbeforeunload = function () {
  if(csvDownloadInProgress){
    return null;
  }
  return "Data will be lost if you leave the page, are you sure?";
};
interface RecordingData {
  label: string;
  fullLabel: string;
  unit: string;
  unitSymbol: string;
  avg: string;
  min: number;
  max: number;
}

interface ChartSeries {
  name: string; 
  data: any[];
}

interface Recording { 
  time: any; 
  name: string;
  duration: number; 
  data: RecordingData[]; 
  rawData: any;
  sampleRate: number;
}

interface RecordingInfo { 
  index: number; 
  recordingName: string;
}

interface YAxisDetails {
  axisTitle: string; 
  axisColor: string;
  isLogarithmic: boolean;
  floatingPoints: number;
  maxValue: number|null;
}

export default Vue.extend(
  {
    name: 'Home',
    components: {
      'sensor-value-conductivity': SensorValueConductivity,
      'sensor-value': SensorValue,
      'session-card': SessionCard
    },
    destroyed() {
      if (chartUpdator) {
        clearInterval(chartUpdator);
      }
    },
    mounted() {
      XAXIS_DATA_POINT_DENSITY = XAXISRANGE * this.sampleRate;
      serialService.onSerialData = (decodedData: SensorReadResult) => {
        this.latestValues = decodedData.decodedValues as SensorDecodedValue[];
        this.processValues();
        this.sensorType = decodedData.sensorType;
        if (!this.colorApplied) {
          this.allSampleRates = SAMPLE_RATE_BY_SENSOR[this.sensorType];
          this.resetChart();
          this.lastConnectedSensorName = this.sensorType;
          this.gtag('sensor identified', 'serial', `${this.sensorType} sensor connected`);
          this.startChartUpdator();
          this.setYAxisProperties();
          this.sampleRate = 1;
        }
      };
      serialService.onSerialConnection = (connect : boolean) => {
        this.isSensorConnected = connect;
        if (!connect) {
          this.animationIntensity = 0;
          this.colorApplied = false;
          this.latestValues = [];
          if (chartUpdator) {
            clearInterval(chartUpdator)
          }
          if (this.isRecording) {
            this.isRecording = false;
            this.saveRecording();
          }
        } else {
          this.startChartUpdator();
        }
      };
      serialService.onFirmwareUpdateAvailable = (isNewFirmwareAvailable : boolean) =>{
        this.isNewFirmwareAvailable = isNewFirmwareAvailable;
      }

    },
    created(){
      this._log("created");
    },
    data() {
      return {
        app_version: getEnv('VUE_APP_VERSION'),
        animationIntensity: 0,
        allSampleRates: { "1 sample every minute": 1 / 60, "1 sample every 30s": 1 / 30, "1 sample every 5s": 1 / 5, "1 sample every second": 1, "5 samples every second": 5 } as {[key: string]: number},
        sampleRate: 1,
        lastConnectedSensorName: "",
        resetInterval: 60000,
        colorApplied: false,
        sensorType: "",
        isSensorConnected: false,
        currentSessionStart: null as any,
        isRecording: false,
        latestValues: [] as SensorDecodedValue[],
        options: {
          colors: [COLOR.DEFAULT, COLOR.DEFAULT],
          tooltip: { enabled: false },
          chart: {
            fontFamily: "Roboto",
            foreColor: COLOR.DEFAULT,
            id: 'kiwrious-chart',
            height: 350,
            type: 'line',
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            },
            animations: {
              enabled: true,
              easing: 'linear',
              dynamicAnimation: {
                speed: UPDATE_INTERVAL
              }
            },
          },
          stroke: {
            curve: 'smooth',
            width: 4
          },
          legend: {
            fontSize: LEGEND_TEXT_SIZE,
            labels: {
              colors: [COLOR.DEFAULT]
            },
            showForSingleSeries: true,
            onItemClick: {
              toggleDataSeries: false
            },
            onItemHover: {
              highlightDataSeries: false
            },
            showForNullSeries: false,
            showForZeroSeries: false,
          },
          yaxis: [
            {
              min: 0,
              tickAmount: 5,
              title: { text: UNIT.DEFAULT, style: { color: COLOR.DEFAULT, fontSize: YAXIS_TITLE_SIZE } },
              axisTicks: {
                color: COLOR.DEFAULT
              },
              axisBorder: {
                color: COLOR.DEFAULT
              },
              labels: {
                style: {
                  colors: [COLOR.DEFAULT],
                  fontSize: YAXIS_LABELS_SIZE
                }
              }
            },
            {
              min: 0,
              tickAmount: 5,
              opposite: true,
              title: { text: UNIT.DEFAULT, style: { color: COLOR.DEFAULT, fontSize: YAXIS_TITLE_SIZE } },
              axisTicks: {
                color: COLOR.DEFAULT
              },
              axisBorder: {
                color: COLOR.DEFAULT
              },
              labels: {
                style: {
                  colors: [COLOR.DEFAULT],
                  fontSize: YAXIS_LABELS_SIZE
                }
              }
            }
          ],
          xaxis: {
            type: XAXISTYPE,
            range: XAXISRANGE,
            tickAmount: 12,
            labels: {
              format: 'mm:ss',
              style: {
                fontSize: XAXIS_LABELS_SIZE
              },
              formatter: function (val: any, index: number) {
                return val.toFixed(0);
              },
              // offsetX: 50,
            },
            axisTicks: {
              color: COLOR.DEFAULT,
              // offsetX: 50,
            },
            axisBorder: {
              color: COLOR.DEFAULT
            }
          },
          grid: {
            borderColor: COLOR.DEFAULT
          },
        },
        series: [
          {
            name: '-',
            data: []
          },
          {
            name: '-',
            data: []
          }
        ] as ChartSeries[],
        recordings: [] as Recording[],
        isSensorReady: false,
        isNewFirmwareAvailable: false
      }

    },
    methods: {
      _log(...msg:any): void {
        console.log("|Home|", ...msg);
      },
      _err(...msg:any): void {
        console.error("|Home|", ...msg);
      },
      processValues(){
        this.latestValues.forEach(element => {
          if(element.type === 'object'){
            let vt = "";
            switch(element.label){
              case "Con":
                this.isSensorReady = true; // always true because there's no pre processing
                if (element.value.status !== CONDUCTIVITY_RESULT_STATUS.READY){
                  element.value.value = CONDUCTIVITY_DEFAULT[element.value.status]
                }
              break;
              case "Voc":
                this.isSensorReady = element.value.status === VOC_RESULT_STATUS.READY;
                if (!this.isSensorReady) {
                  vt = "VOC sensor warming up";
                }
                (this.$refs.chart as any)?.updateOptions({
                  noData: {
                    text: vt,
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: -30,
                    style: {
                      fontSize: '26px',
                    }
                  }
                });
              break;
              case "HeartRate":
                this.isSensorReady = element.value.status === HEART_RATE_RESULT_STATUS.READY;
              break;        
            }
          } else {
            if(element.label === "Hum"){
              this.animationIntensity = element.value;
            }
            this.isSensorReady = true;
          }
        });
      },
      resetChart() {
        this._log("reset chart");
        if (chartUpdator) {
          clearInterval(chartUpdator);
          chartUpdator=0;
        }
        lastDate = 0;
        chartData = [];
        this.series = [];
      },
      chartUpdatorSteps(){
        const self = this;
        if (!self.latestValues) {
          this._log("return - latest values are empty");
          return;
        }
        if (!chartData.length) {
          for (let j = 0; j < self.latestValues.length; j++) {
            chartData.push([]);
          }
        }
        if (!this.isSensorReady) {
          this._log("return - sensor is not ready");
          return;
        }
        if (!self.series.length) {
          for (let j = 0; j < self.latestValues.length; j++) {
            self.series.push({
              name: '-',
              data: []
            });
          }
        }
        lastDate = lastDate + TIME_DELTA
        const timeStamp = lastDate;// Math.floor(Date.now());

        if (self.latestValues && self.isSensorConnected) {

          const chartSeriesData = [];

          for (let i = 0; i < self.latestValues.length; i++) { // for each observable

            // fill dummy values
            if (chartData[i].length === 0) {
              let tempTimeCounter = timeStamp;
              for (let k = 0; k < XAXIS_DATA_POINT_DENSITY; k++) { // fill dummy values
                chartData[i].push({ x: tempTimeCounter, y: -1 });
                tempTimeCounter = tempTimeCounter + TIME_DELTA;
              }

              chartData[i].push({ x: timeStamp, y: -1 }); // to handle unexpected curve
            }
            else {
              if (self.latestValues[i].type === "number") {
                chartData[i].push({ x: timeStamp, y: self.latestValues[i].value });
              } else {
                chartData[i].push({ x: timeStamp, y: self.latestValues[i].value.value });
              }
            }

            chartSeriesData.push({ data: chartData[i], name: OBSERVABLE_FULL_NAME[self.latestValues[i].label] });
            self.series[i].data = chartData[i];
            self.series[i].name = self.latestValues[i].label;
          }
          (self.$refs.chart as any)?.updateSeries(chartSeriesData);

        }
      },
      startChartUpdator() {
        this.chartUpdatorSteps();
        chartUpdator = window.setInterval(this.chartUpdatorSteps, UPDATE_INTERVAL);
      },    
      setYAxisProperties() {
        const yparams: YAxisDetails[] = [];
        for (let j = 0; j < this.latestValues.length; j++) {
          this.options.colors[j] = COLOR_BY_SENSOR_TYPE[this.sensorType][j];
          yparams.push(YAXIS_TITLE_AND_COLOR[this.latestValues[j].label]);
        }
        this.updateYAxisDetails(yparams);
        this.colorApplied = true;
      },
      async connectSensor() {
        this.gtag('connect sensor', 'serial', 'plug sensor');
        await serialService.connectAndReadAsync();
      },
      getYAxisObject(axisTitle: string, axisColor: string, floatingPoints: number, maxY: number|null, isOpposite = false){
        const self = this;
        return {
          min: 0,
          // max : fff,
          max: function(max: any){
            if(maxY){
              return maxY // use predefined max from config
            } else {   
              if(self.sensorType === SENSOR_TYPE.TEMPERATURE || self.sensorType === SENSOR_TYPE.TEMPERATURE2){

                const v1 = self.latestValues[0].value;
                const v2 = self.latestValues[1].value;
                const v1_scale = (Math.floor(v1 / yAxisBandSize) + 1) * yAxisBandSize;
                const v2_scale = (Math.floor(v2 / yAxisBandSize) + 1) * yAxisBandSize;

                chartScallerCounter++;

                if(v1_scale!==v2_scale){
                  if(v1_scale>v2_scale && v1_scale>peakRangeMax){
                    peakInRange = true;
                    chartScallerCounter = 0;
                    peakRangeMax = v1_scale;
                  } else if(v1_scale<v2_scale && v2_scale>peakRangeMax) {
                    peakInRange = true;
                    chartScallerCounter = 0;
                    peakRangeMax = v2_scale;
                  }
                }

                if (chartScallerCounter > XAXISRANGE * 2){
                  chartScallerCounter=0;
                  peakInRange = false;
                  return v1_scale;
                } else {
                  if(peakInRange){
                    return peakRangeMax;
                  } else {
                    return v1_scale
                  }
                }

              }
              return max; // use apex auto scalling 
            }
          },
          tickAmount: 5,
          opposite: isOpposite,
          title: { text: axisTitle, style: { color: axisColor, fontSize: YAXIS_TITLE_SIZE } },
          labels: {
            style: {
              colors: [axisColor],
              fontSize: YAXIS_LABELS_SIZE
            }
          },
          decimalsInFloat: floatingPoints,
        }
      },
      updateYAxisDetails(yAxisDetails: YAxisDetails[]) {
        if (yAxisDetails.length>2){
          throw new Error("Number of graph dimensions should not exceed 2");
        }
        const yx: any[] = [];
        yAxisDetails.forEach((element, index)=>{
          yx.push(this.getYAxisObject(element.axisTitle, element.axisColor, element.floatingPoints, element.maxValue, index===1));
        });  
        (this.$refs.chart as any)?.updateOptions({
          yaxis: yx
        });
      },

      recordValues() {
        this.isRecording = !this.isRecording;
        if (!this.isRecording) { // recording stopped
          this.gtag('record stop', 'recording', 'stop recording');
          if (chartUpdator) {
            clearInterval(chartUpdator);
          }
          this.saveRecording();
        } else {
          this.gtag('record start', 'recording', 'start recording');
          this.currentSessionStart = moment.default();// Math.floor(Date.now());
          this.resetChart();
          this.startChartUpdator();
        }
      },
      saveRecording() {

        let filteredChartData: any[] = [];
        const tdelta = 1 / this.sampleRate;
        for (let i = 0; i < chartData.length; i++) { // for each observable
          chartData[i].splice(0, XAXIS_DATA_POINT_DENSITY); // remove dummy data points
          if(chartData[i].length>1){
            chartData[i][0].y = chartData[i][1].y // remove y=-1 dummy value (apex workaround to hide zeroth line)
          }
          filteredChartData.push([]);
          for (let k = 0; k < chartData[i].length; k++) { // filter additional data points (drawing more data points in chart than selected lower s rate)
            if (k % tdelta === 0) {
              filteredChartData[i].push(chartData[i][k]);
            }
          }
        }

        if(this.sampleRate>1){
          filteredChartData = chartData;
        }

        const rec: Recording = {
          time: this.currentSessionStart,
          name: `Recording ${this.recordings.length+1} ${this.sensorType.toLowerCase()}`,
          duration: lastDate,
          data: [],
          rawData: filteredChartData,
          sampleRate: this.sampleRate
        };

        for (let i = 0; i < filteredChartData.length; i++) {
          const ys = filteredChartData[i].map((d: { y: string }) => parseInt(d.y));
          const MIN: number = Math.min(...ys);
          const MAX: number = Math.max(...ys);
          const SUM: number = ys.reduce((a: any, b: any) => a + b, 0);
          const AVG: string = (SUM / (ys.length)).toFixed(1);
          const rd: RecordingData = {
            label: SHORT_OBSERVABLE[OBSERVABLE[this.series[i].name]],
            fullLabel: OBSERVABLE_FULL_NAME[this.series[i].name],
            unit: UNIT[OBSERVABLE[this.series[i].name]],
            unitSymbol: UNIT_SYMBOL[OBSERVABLE[this.series[i].name]],
            avg: AVG,
            min: MIN,
            max: MAX
          };
          rec.data.push(rd);
        }
        this.recordings.push(rec);

      },
      renameRecording(info: RecordingInfo) {
        this.recordings[info.index].name = info.recordingName;
      }, 
      exportAllData() {
        for (let i = 0; i < this.recordings.length; i++) {
          this.exportData(i);
        }
      },
      gtag(action:string, category:string, label:string, value = 0) {
        this.$gtag.event(action, {
          'event_category': category,
          'event_label': label,
          'value': value
        });
      },
      exportData(index: number) {

        // find selected recording data //
        const selectedRecording: Recording = this.recordings[index];

        this.gtag('export data', 'recording', `dataset ${selectedRecording.name}`);

        // create data row bucket //
        const rows = [];

        // add header row //
        const headerRow = [];
        headerRow.push("Timestamp");
        headerRow.push("Relative Time (s)");
        selectedRecording.data.forEach((element: RecordingData) => {
          const observableWithUnits = `${element.fullLabel} (${element.unitSymbol})`;
          headerRow.push(observableWithUnits);
        });
        headerRow.push("Recording Name")
        rows.push(headerRow);

        // add data rows //
        for (let i = 0; i < selectedRecording.rawData[0].length; i++) {
          const dataRow = [];
          dataRow.push(selectedRecording.time.add(1 / this.sampleRate, 's').format('DD MMM YYYY h:mm:ss:SSS')); // add absolute time
          if (selectedRecording.sampleRate>1){
            dataRow.push((selectedRecording.rawData[0][i].x).toFixed(2) * 1000); // add relative time As Milliseconds
            rows[0][1] = "Relative Time (ms)";
          } else {
            dataRow.push(selectedRecording.rawData[0][i].x - 1); // add relative time As Seconds
          }
          selectedRecording.rawData.forEach((element: { y: any }[]) => {
            dataRow.push(element[i].y); // add observable values
          });
          dataRow.push(selectedRecording.name);
          rows.push(dataRow);
        }

        // generate csv file //
        const csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);

        // prepare download link //
        csvDownloadInProgress = true;
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${selectedRecording.name}.csv`);
        //ocument.body.appendChild(link); // Required for FF

        // trigger auto download //
        link.click();
        csvDownloadInProgress = false;
      },

      resetData() {
        // Alternatively, you can also reset the data at certain intervals to prevent creating a huge series 
        chartData[0] = chartData[0].slice(chartData[0].length - 10, chartData[0].length);
        chartData[1] = chartData[1].slice(chartData[1].length - 10, chartData[1].length);
      }
    },
    watch: {
      sampleRate(){
        if(this.sampleRate){
          
          this.resetChart();         

          UPDATE_INTERVAL = (1000 / this.sampleRate);
          TIME_DELTA = 1 / this.sampleRate;
          XAXISRANGE = 60;
          SAMPLING_RATE = 1/ TIME_DELTA;
          XAXIS_DATA_POINT_DENSITY = XAXISRANGE * this.sampleRate;
          let FORMAT_XLABLES = false;

          if(this.sampleRate<1){
            UPDATE_INTERVAL = 1000;
            TIME_DELTA = 1;
            XAXISRANGE = XAXIS_RANGE_BY_INTERVAL[this.sampleRate];
            SAMPLING_RATE = 1;
            XAXIS_DATA_POINT_DENSITY = 60;
            FORMAT_XLABLES = true;
          }

          const HEAD_TIME_DIVISOR = Math.round((1/this.sampleRate));
          const AXIS_TICK_INTERVAL = 5;
          const NO_OF_AXIS_TICKS = 12;

          (this.$refs.chart as any)?.updateOptions({
            chart: {
              fontFamily: "Roboto",
              foreColor: COLOR.DEFAULT,
              id: 'kiwrious-chart',
              height: 350,
              type: 'line',
              toolbar: {
                show: false
              },
              zoom: {
                enabled: false
              },
              animations: {
                enabled: true,
                easing: 'easyinout',
                dynamicAnimation: {
                  speed: UPDATE_INTERVAL
                }
              },
            },
            xaxis: {
              type: XAXISTYPE,
              range: XAXIS_RANGE_BY_INTERVAL[this.sampleRate],
              decimalsInFloat: 1,
              tickAmount: NO_OF_AXIS_TICKS,
              labels: {
                // format: 'mm:ss',
                formatter: function (val: any, index: number) {
                  if(!FORMAT_XLABLES){
                    return val.toFixed(0);
                  }
                  const xxx = val - val % AXIS_TICK_INTERVAL;
                  if (xxx % HEAD_TIME_DIVISOR === 0){
                    return xxx;
                  } else {
                    return "-";
                  }          
                },
                style: {
                  fontSize: XAXIS_LABELS_SIZE
                }
              },
              axisTicks: {
                color: COLOR.DEFAULT
              },
              axisBorder: {
                color: COLOR.DEFAULT
              }
            },
            stroke: {
              curve: 'smooth',
              width: 3//CURVE_SIZE_BY_SAMPLE_RATE[this.sampleRate]
            },
          });
          this.allSampleRates = SAMPLE_RATE_BY_SENSOR[this.sensorType];
          this.setYAxisProperties();
          this.startChartUpdator();
        }     
      }
    },
    computed: {
      recordingButtonText() {
        if (this.isRecording) {
          return "Stop";
        } else {
          return "Record";
        }
      }
    }
  }
);


