import { Workbox } from "workbox-window";

let wb;

if ("serviceWorker" in navigator) {
    wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);

    let registration;

    wb.addEventListener('activated', (event) => {
        // `event.isUpdate` will be true if another version of the service
        // worker was controlling the page when this version was registered.
        if (!event.isUpdate) {
            console.log('Service worker activated for the first time!');

            // If your service worker is configured to precache assets, those
            // assets should all be available now.
        }
    });

    wb.addEventListener('waiting', (event) => {
        console.log("waiting");
        wb.messageSkipWaiting();
    });

    wb.addEventListener("controlling", () => {
        console.log("controlling");
        window.location.reload();
    });

    wb.addEventListener("installed", (event) => {
        console.log("SW installed");
    });

    wb.addEventListener('message', (event) => {
        if (event.data.type === 'CACHE_UPDATED') {
            const { updatedURL } = event.data.payload;

            console.log(`A newer version of ${updatedURL} is available!`);
        }
    });

    registration = wb.register();
} else {
    wb = null;
}

export default wb;