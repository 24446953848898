import Vue from 'vue';

export default Vue.extend( {
 
  props: [
    'conductivity_value',
  ],
  data(){
    return {
      defaultPosition: 'translate(240,120)'
    }
  },
  methods: {
    ui_value() {
      if (!this.conductivity_value) {
        return null;
      }
      return this.conductivity_value.value?.toString();
    }
  }
  
});


