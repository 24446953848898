import Vue from 'vue';

export default Vue.extend( {
  name: 'sensor-value-uv',
  components: {},
  props: [
    'lux_value',
    'uv_index_value'
  ],
  data() {
    return {
      uv_color: '#00D472',
      uv_label: '',
      defaultUVPosition:'translate(150,100)',
      shiftedUVPosition:'translate(10,10)',
      defaultLuxPosition:'translate(330,100)',
      shiftedLuxPosition:'translate(10,180)'
    }
  },
  watch: {
    uv_index_value(newVal: number) {
      const raw = newVal;
      const uvProps = this.getUvProperties(raw);
      this.uv_color = uvProps.color;
      this.uv_label = uvProps.label;
    },
  },
  methods: {
    _log(...msg: any[]) {
      console.log('|SensorValueUV|', ...msg);
    },

    getUvProperties(value: number) {
      if (value >= 11) {
        return { color: "#8B25E4", label: "EXTREME" };
      } else if (value >= 8) {
        return { color: "#E30E37", label: "VERY HIGH" };
      } else if (value >= 6) {
        return { color: "#FF6B2F", label: "HIGH" };
      } else if (value >= 3) {
        return { color: "#FEBF3A", label: "MEDIUM" };
      } else {
        return { color: "#00D472", label: "LOW" };
      }
    },
  }
}

);
