
export default {
  name: 'sensor-value-humidity',
  components: {},
  props: [
    'temperature_value',
    'humidity_value'
  ],
  data () {
    return {
      defaultPosition:'translate(200,30)'
    }
  },
  methods: {
    _log(...msg: any[]) {
      console.log('|SensorValueHumidity|', ...msg);
    },
  }
}


