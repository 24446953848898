import Vue from 'vue';

import SensorValueUV from "@/components/sensor/value/SensorValueUV/index.vue";
import SensorValueHumidity from '@/components/sensor/value/SensorValueHumidity/index.vue';
import SensorValueVOC from '@/components/sensor/value/SensorValueVOC/index.vue';
import SensorValueConductivity from '@/components/sensor/value/SensorValueConductivity/index.vue';

import { SENSOR_TYPE } from 'kiwrious-webserial/lib/service/SerialRawValue';

import SensorValueHeartRate from '../SensorValueHeartRate/index.vue';
import SensorValueTemperature from '../SensorValueTemperature/index.vue';

export default Vue.extend( {
  name: 'sensor-value',
  components: {
    'sensor-value-uv': SensorValueUV,
    'sensor-value-humidity': SensorValueHumidity,
    'sensor-value-voc': SensorValueVOC,
    'sensor-value-conductivity': SensorValueConductivity,
    'sensor-value-heartrate': SensorValueHeartRate,
    'sensor-value-temperature': SensorValueTemperature,
  },
  props: ["requiredx", "sensor_type", "sensor_values"],
  data() {
    return {
      SENSOR_TYPE: SENSOR_TYPE,
      humidity_value: 0,
      temperature_value: 0,
      uv_index_value: 0,
      lux_value: 0,
      voc_value: 0,
      conductivity_value: 0 as number,
      heartrate_value: 0,
      infrared_temperature_value:0,
      ambient_temperature_value: 0
    }
  },
  watch: {
    sensor_values() {
      switch(this.sensor_type){
        case SENSOR_TYPE.CONDUCTIVITY:
          this.conductivity_value = this.sensor_values[0].value;
          break;
        case SENSOR_TYPE.HUMIDITY:
          this.humidity_value = this.sensor_values[1].value;
          this.temperature_value = this.sensor_values[0].value;
          break;
        case SENSOR_TYPE.UV:
          this.uv_index_value = this.sensor_values[1].value;
          this.lux_value = this.sensor_values[0].value;
          break;
        case SENSOR_TYPE.HEART_RATE:
          this.heartrate_value = this.sensor_values[0].value;
          break;
        case SENSOR_TYPE.TEMPERATURE:
          this.infrared_temperature_value = this.sensor_values[1].value;
          this.ambient_temperature_value = this.sensor_values[0].value;
          break;
        case SENSOR_TYPE.TEMPERATURE2:
          this.infrared_temperature_value = this.sensor_values[1].value;
          this.ambient_temperature_value = this.sensor_values[0].value;
          break;
        case SENSOR_TYPE.VOC:
          this.voc_value = this.sensor_values[0].value;
          break;
        default:
          throw new Error(`Unexpected sensor type [${this.sensor_type}]`)
      }
    }
  },
  methods: {
    _log(...msg: any[]) {
      console.log(`|sensorValue|`, ...msg);
    },
    _err(...msg: any[]) {
      console.error(`|sensorValue|`, ...msg);
    }
  }
}
);

