<template>
  <div id="app">
    <Home/>
  </div>
</template>

<script>
import Home from './components/Home/index.vue'

export default {
  name: 'App',
  metaInfo: {
      title: 'Kiwrious Measure',
    },
  components: {
    Home
  }
}
</script>

<style>
@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url(../public/fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}
#app {
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
}
button{
  font-family: "Roboto";
  font-size: 16px;
}
input{
  font-family: "Roboto";
}
select{
  font-family: "Roboto";
}
</style>
