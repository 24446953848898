import Vue from 'vue';

const MILLISECONDS_PER_SECOND = 1000;

export default Vue.extend({
    name: 'Card',
    props: ['details', 'index', 'sensorType'],
    data(){
        return {
            recordingName:this.details.name,
            isRenaming: false
        }
    },
    methods: {
        onRenameDone(){
            this.isRenaming = false;
            this.recordingName = this.recordingName.trim();
            if(!this.recordingName){
                this.recordingName = `Recording ${this.index} ${this.sensorType.toLowerCase()}`;
            }
            this.$emit("onRename", {index: this.index, recordingName: this.recordingName});
        },
        onClickExport(){
            this.$emit("onClickExport", this.index);
        },
        formattedSampleRate(){
            if(this.details.sampleRate<1){
                return (this.details.sampleRate * MILLISECONDS_PER_SECOND).toFixed(0) + "mHz";
            } else {
                return this.details.sampleRate + "Hz";
            }
        }
    }
});