import { VOC_RESULT_STATUS } from "kiwrious-webserial/lib/service/SerialVOCDecoder";
import Vue from 'vue';


export default Vue.extend( {
  name: 'sensor-value-voc',
  props: [
    'voc_value'
  ],
  data() {
    return {
      defaultPosition: "translate(240,120)"
    }
  },
  methods: {
    _log(...msg: any[]) {
      console.log('|SensorValueVOC|', ...msg);
    },
    showProgress() {
      return this.voc_value.status === VOC_RESULT_STATUS.PROCESSING;
    },
    progressValue() {
      return this.voc_value.dataReadyPercentage;
    },
    uiValue() {
      return this.voc_value.value
    }
  }
});


