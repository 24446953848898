import { SENSOR_TYPE } from "kiwrious-webserial/lib/service/SerialRawValue";

export const COLOR: { [key: string]: string } = {
    UV: "#FFFFFF",
    LUX: "#BEAAFF",
    HUMIDITY: "#80C3FF",
    TEMPERATURE: "#FDDCCD",
    CONDUCTIVITY: "#FCD666",
    VOC: "#72E4AB",
    HEART_RATE: "#FFCBE1",
    AMBIENT_TEMP: "#FFFFFF",
    INFRARED_TEMP: "#FABBCC",
    DEFAULT: "#D3D3D3",
    BACKGROUND: "#091a1f"
};

export const COLOR_BY_SENSOR_TYPE: { [key: string]: string[] } = { //toDo: convert to object
    UV: [COLOR.LUX, COLOR.UV],
    HUMIDITY: [COLOR.TEMPERATURE, COLOR.HUMIDITY],
    CONDUCTIVITY: [COLOR.CONDUCTIVITY],
    VOC: [COLOR.VOC],
    HEART_RATE: [COLOR.HEART_RATE],
    TEMPERATURE: [COLOR.AMBIENT_TEMP, COLOR.INFRARED_TEMP],
    TEMPERATURE2: [COLOR.AMBIENT_TEMP, COLOR.INFRARED_TEMP],
};

export const UNIT_SYMBOL: { [key: string]: string } = {
    UV: "UVI",
    LUX: "lux",
    HUMIDITY: "%",
    TEMPERATURE: "°C",
    CONDUCTIVITY: "µS",
    VOC: "ppb",
    HEART_RATE: "bpm",
    AMBIENT_TEMP: "°C",
    INFRARED_TEMP: "°C",
    DEFAULT: ""
}

export const UNIT: { [key: string]: string } = {
    UV: "UV Radiation (UV Index)",
    LUX: "Illuminance (lux)",
    HUMIDITY: "Relative Humidity (%)",
    TEMPERATURE: "Ambient Temperature (°C)",
    CONDUCTIVITY: "Electric Conductance (µS)",
    VOC: "Total VOC (ppb)",
    HEART_RATE: "Heart Rate (bpm)",
    AMBIENT_TEMP: "Ambient Temperature (°C)",
    INFRARED_TEMP: "Infrared Temperature (°C)",
    DEFAULT: ""
}

export const OBSERVABLE: {[key: string]: string} = {
    Uv: "UV",
    Lux: "LUX",
    Hum: "HUMIDITY",
    Temp: "TEMPERATURE",
    Con: "CONDUCTIVITY",
    Voc: "VOC",
    HeartRate: "HEART_RATE",
    AmbientTemp: "AMBIENT_TEMP",
    InfraredTemp: "INFRARED_TEMP",
    DEFAULT: ""
}

export const AXIS_FLOTING_POINTS: { [key: string]: number } = {
    Uv: 1,
    Lux: 0,
    Hum: 0,
    Temp: 0,
    Con: 1,
    Voc: 0,
    HeartRate: 0,
    AmbientTemp: 0,
    InfraredTemp: 0,
}

export const SHORT_OBSERVABLE: { [key: string]: string } = {
    UV: "UV",
    LUX: "LUX",
    HUMIDITY: "Hum",
    TEMPERATURE: "Tem",
    CONDUCTIVITY: "Con",
    VOC: "VOC",
    HEART_RATE: "HR",
    AMBIENT_TEMP: "A.Tem",
    INFRARED_TEMP: "I.Tem",
    DEFAULT: ""
}

export const OBSERVABLE_FULL_NAME: { [key: string]: string } = {
    Uv: "UV Radiation",
    Lux: "Illuminance",
    Hum: "Relative Humidity",
    Temp: "Ambient Temperature",
    Con: "Electric Conductance",
    Voc: "VOC",
    HeartRate: "Heart Rate",
    AmbientTemp: "Ambient Temperature",
    InfraredTemp: "Infrared Temperature",
    DEFAULT: ""
}

export const SENSOR_OBSERVABLES = {
    UV: [OBSERVABLE.Uv, OBSERVABLE.Lux],
    HUMIDITY: [OBSERVABLE.Hum, OBSERVABLE.Temp],
    CONDUCTIVITY: [OBSERVABLE.Con],
    VOC: [OBSERVABLE.Voc],
    HEART_RATE: [OBSERVABLE.HeartRate],
    BODY_TEMP: [OBSERVABLE.AmbientTemp, OBSERVABLE.InfraredTemp]
}

export const YAXIS_TITLE_AND_COLOR: { [key: string]: { axisTitle: string; axisColor: string; floatingPoints: number; maxValue: number|null; isLogarithmic: boolean} } = {
    Uv: { axisTitle: UNIT.UV, axisColor: COLOR.UV, floatingPoints: AXIS_FLOTING_POINTS.Uv, maxValue:15, isLogarithmic: false },
    Lux: { axisTitle: UNIT.LUX, axisColor: COLOR.LUX, floatingPoints: AXIS_FLOTING_POINTS.Lux, maxValue: null,  isLogarithmic: true},
    Hum: { axisTitle: UNIT.HUMIDITY, axisColor: COLOR.HUMIDITY, floatingPoints: AXIS_FLOTING_POINTS.Hum, maxValue: 100,  isLogarithmic: false },
    Temp: { axisTitle: UNIT.TEMPERATURE, axisColor: COLOR.TEMPERATURE, floatingPoints: AXIS_FLOTING_POINTS.Temp, maxValue: null, isLogarithmic: false },
    Con: { axisTitle: UNIT.CONDUCTIVITY, axisColor: COLOR.CONDUCTIVITY, floatingPoints: AXIS_FLOTING_POINTS.Con, maxValue: null, isLogarithmic: true },
    Voc: { axisTitle: UNIT.VOC, axisColor: COLOR.VOC, floatingPoints: AXIS_FLOTING_POINTS.Voc, maxValue: null, isLogarithmic: false },
    HeartRate: { axisTitle: UNIT.HEART_RATE, axisColor: COLOR.HEART_RATE, floatingPoints: AXIS_FLOTING_POINTS.HeartRate, maxValue: null, isLogarithmic: false },
    AmbientTemp: { axisTitle: UNIT.AMBIENT_TEMP, axisColor: COLOR.AMBIENT_TEMP, floatingPoints: AXIS_FLOTING_POINTS.AmbientTemp, maxValue: null, isLogarithmic: false  },
    InfraredTemp: { axisTitle: UNIT.INFRARED_TEMP, axisColor: COLOR.INFRARED_TEMP, floatingPoints: AXIS_FLOTING_POINTS.InfraredTemp, maxValue: null, isLogarithmic: false },
}

export const CONDUCTIVITY_RESULT_STATUS = {
    MAX: 'MAX',
    MIN: 'MIN',
    READY: 'READY',
};

export const CONDUCTIVITY_DEFAULT: { [key: string]: number} = {
    MIN: 0,
    MAX: 200000
}

export const XAXIS_RANGE_BY_INTERVAL: { [key: number]: number } = {
    [1/60]: 60,
    [1/30]: 60,
    [1/5]: 60,
    1: 60,
    2: 60,
    5: 60
}

const higher_sample_rates = { "1 sample every second": 1, "2 sample every second": 2, "5 samples every second": 5 };
const lower_sample_rates = { "1 sample every minute": 1 / 60, "1 sample every 30s": 1 / 30, "1 sample every 5s": 1 / 5, "1 sample every second": 1};
const mix_sample_rates = { "1 sample every minute": 1 / 60, "1 sample every 30s": 1 / 30, "1 sample every 5s": 1 / 5, "1 sample every second": 1, "5 samples every second": 5 };

export const SAMPLE_RATE_BY_SENSOR: {[key: string]: {}} = {
    [SENSOR_TYPE.UV]: mix_sample_rates,
    [SENSOR_TYPE.HUMIDITY]: lower_sample_rates,
    [SENSOR_TYPE.VOC]: lower_sample_rates,
    [SENSOR_TYPE.CONDUCTIVITY]: higher_sample_rates,
    [SENSOR_TYPE.TEMPERATURE]: lower_sample_rates,
    [SENSOR_TYPE.TEMPERATURE2]: lower_sample_rates,
    [SENSOR_TYPE.HEART_RATE]: higher_sample_rates
}