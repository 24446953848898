import { HEART_RATE_RESULT_STATUS } from "kiwrious-webserial/lib/service/HeartRateProcessor";
import Vue from 'vue';


export default Vue.extend( {
  props: ["heartrate_value"],
  methods: {
    ui_value(){
      if (!this.heartrate_value) {
        return null;
      }

      const heartRateResult = this.heartrate_value;

      if (heartRateResult.value && heartRateResult.status === HEART_RATE_RESULT_STATUS.READY) {
        return heartRateResult.value.toFixed(0);
      }

      return null;
    },

    is_too_high() {
        const isTooHigh = this.heartrate_value && (this.heartrate_value.status === HEART_RATE_RESULT_STATUS.TOO_HIGH)

        return isTooHigh;
      },
    is_too_low() {
        const isTooLow = this.heartrate_value && (this.heartrate_value.status === HEART_RATE_RESULT_STATUS.TOO_LOW);

        return isTooLow;
      },
   is_processing() {
      return this.heartrate_value && (this.heartrate_value.status === HEART_RATE_RESULT_STATUS.PROCESSING);
    }
  }

  
});

