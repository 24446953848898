import Vue from 'vue'
import App from './App.vue'
import VueGtag from "vue-gtag";

import '@/plugins/apexcharts'

const wb = require("./registerServiceWorker")

Vue.use(VueGtag, {
  config: { id: "UA-178036040-5" },
  params: {
    send_page_view: true
  }
});

Vue.prototype.$workbox = wb;

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
