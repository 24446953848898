
export default {
  name: 'sensor-value-temperature',
  components: {},
  props: [
    'infrared_temperature_value',
    'ambient_temperature_value'
  ],
  data () {
    return {
      defaultPosition: "translate(200,120)"
    }
  },
  methods: {
    _log(...msg: any[]) {
      console.log('|SensorValueTemperature|', ...msg);
    },
  }
}


